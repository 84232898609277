import {Avatar, Button, Card, Layout, Popconfirm, Switch} from 'antd';
import {useContext, useState} from 'react';
import {LogoutOutlined, BulbOutlined, BulbFilled} from '@ant-design/icons';
import {Link, useNavigate} from 'react-router-dom';
import logo from '../../assets/img/logo.svg';
import logo_light from '../../assets/img/logo_light.svg';
import profile from '../../assets/img/profile.png';
import CommonStore from '../../store/CommonStore';
import {SiderTheme} from 'antd/lib/layout/Sider';
import {useTranslation} from 'react-i18next';
import {useCookies} from 'react-cookie';
import AppRouter from '../../router';
import NavigationBar from './NavigationBar';

const {Sider, Content} = Layout;

const MainLayout = () => {
  const {t} = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const navigate = useNavigate();

  //
  const [, , removeCookie] = useCookies(['token']);

  //
  const [theme, setTheme] = useState<SiderTheme>(
    (localStorage.getItem('theme') || 'dark') as SiderTheme,
  );

  //
  const logout = () => {
    storeCommon.setUser(null);
    localStorage.removeItem('token');

    removeCookie('token', {
      path: '/',
    });

    navigate('/login');
  };

  return (
    <Layout>
      <Sider
        width={256}
        theme={theme}
        style={{
          minHeight: '100vh',
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}>
        <Link to='/'>
          <img
            src={theme === 'dark' ? logo_light : logo}
            alt='logo'
            width='150px'
            style={{
              display: 'block',
              margin: '25px auto',
            }}
          />
        </Link>

        <>
          <NavigationBar theme={theme} />
          <Card
            style={{width: '100%', marginTop: 'auto'}}
            className={theme === 'dark' ? 'nl-card-dark' : ''}
            actions={[
              <Switch
                checkedChildren={<BulbFilled />}
                unCheckedChildren={<BulbOutlined />}
                defaultChecked={theme !== 'dark'}
                onChange={value => {
                  setTheme(value ? 'light' : 'dark');
                  localStorage.setItem('theme', value ? 'light' : 'dark');
                }}
              />,
              <Popconfirm
                placement='top'
                title={t('Are you sure?')}
                onConfirm={logout}
                okText={t('Yes')}
                cancelText={t('No')}>
                <Button
                  size='small'
                  type='primary'
                  shape='round'
                  icon={<LogoutOutlined />}
                />
              </Popconfirm>,
            ]}>
            <Card.Meta
              avatar={<Avatar src={profile} />}
              title={storeCommon.user?.username}
              description={storeCommon.user?.email}
            />
          </Card>
        </>
      </Sider>
      <Content style={{padding: '30px', marginLeft: 256, minHeight: '100vh'}}>
        <AppRouter />
      </Content>
    </Layout>
  );
};

export default MainLayout;
