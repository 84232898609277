import {Result} from 'antd';
import {lazy, Suspense} from 'react';
import {Routes, Route} from 'react-router-dom';

const BlockInfo = lazy(() => import('../pages/BlockInfo'));
const EditBlockInfo = lazy(() => import('../pages/BlockInfo/EditBlockInfo'));
const BrandItems = lazy(() => import('../pages/BrandItems'));
const EdiBrandItems = lazy(() => import('../pages/BrandItems/EditBrandItems'));
const TeamItems = lazy(() => import('../pages/TeamItems'));
const EditTeamItem = lazy(() => import('../pages/TeamItems/EditTeamItem'));

const ApplicationItems = lazy(() => import('../pages/ApplicationItems'));
const EditApplicationItems = lazy(
  () => import('../pages/ApplicationItems/EditApplicationItems'),
);
const OfferItems = lazy(() => import('../pages/OfferItems'));
const EditOfferItems = lazy(() => import('../pages/OfferItems/EditOfferItems'));

const SolutionItems = lazy(() => import('../pages/SolutionItems'));
const EditSolutionItems = lazy(
  () => import('../pages/SolutionItems/EditSolutionItems'),
);

const Contact = lazy(() => import('../pages/Contact'));
const EditContact = lazy(() => import('../pages/Contact/EditContact'));

const News = lazy(() => import('../pages/News'));
const EditNews = lazy(() => import('../pages/News/EditNews'));

const Offices = lazy(() => import('../pages/Offices'));
const EditOffices = lazy(() => import('../pages/Offices/EditOffices'));

const BrochuresItems = lazy(() => import('../pages/BrochuresItems'));
const EditBrochuresItems = lazy(
  () => import('../pages/BrochuresItems/EditBrochuresItems'),
);

const AboutItems = lazy(() => import('../pages/AboutItems'));
const EditAboutItems = lazy(() => import('../pages/AboutItems/EditAboutItems'));

const HistoryItems = lazy(() => import('../pages/HistoryItems'));
const EditHistoryItems = lazy(
  () => import('../pages/HistoryItems/EditHistoryItems'),
);

const StaffItems = lazy(() => import('../pages/StaffItems'));
const EditStaffItems = lazy(() => import('../pages/StaffItems/EditStaffItems'));

const Terms = lazy(() => import('../pages/Terms'));
const EditTerms = lazy(() => import('../pages/Terms/EditTerms'));

const AppRouter = () => {
  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path='/block-info' element={<BlockInfo />} />
        <Route path='/block-info/:blockInfoId' element={<EditBlockInfo />} />
        {/* BRANDS */}
        <Route path='/brand-items' element={<BrandItems />} />
        <Route path='/brand-items/:brandItemId' element={<EdiBrandItems />} />
        <Route path='/brand-items/create' element={<EdiBrandItems />} />
        {/* TEAM */}
        <Route path='/team-items' element={<TeamItems />} />
        <Route path='/team-items/:teamItemId' element={<EditTeamItem />} />
        <Route path='/team-items/create' element={<EditTeamItem />} />
        {/* Application */}
        <Route path='/application-items' element={<ApplicationItems />} />
        <Route
          path='/application-items/:applicationItemId'
          element={<EditApplicationItems />}
        />
        <Route
          path='/application-items/create'
          element={<EditApplicationItems />}
        />
        {/* Offers articles */}
        <Route path='/our-offer/offer-items' element={<OfferItems />} />
        <Route
          path='/our-offer/offer-items/:offerItemId'
          element={<EditOfferItems />}
        />
        <Route
          path='/our-offer/offer-items/create'
          element={<EditOfferItems />}
        />
        {/* Our offer solution card */}
        <Route path='/our-offer/solution-items' element={<SolutionItems />} />
        <Route
          path='/our-offer/solution-items/:solutionItemId'
          element={<EditSolutionItems />}
        />
        <Route
          path='/our-offer/solution-items/create'
          element={<EditSolutionItems />}
        />
        {/* // */}
        {/* // */}
        <Route path='/contact' element={<Contact />} />
        <Route path='/contact/:contactId' element={<EditContact />} />
        <Route path='/news/create' element={<EditNews />} />
        <Route path='/news' element={<News />} />
        <Route path='/news/:newsId' element={<EditNews />} />
        <Route path='/offices/create' element={<EditOffices />} />
        <Route path='/offices' element={<Offices />} />
        <Route path='/offices/:officeId' element={<EditOffices />} />
        {/*brochures pages */}
        <Route path='/brochures-items' element={<BrochuresItems />} />
        <Route
          path='/brochures-items/:brochuresItemId'
          element={<EditBrochuresItems />}
        />
        <Route
          path='/brochures-items/create'
          element={<EditBrochuresItems />}
        />
        {/*About pages */}
        <Route path='/about/about-items' element={<AboutItems />} />
        <Route
          path='/about/about-items/:aboutItemId'
          element={<EditAboutItems />}
        />
        <Route path='/about/about-items/create' element={<EditAboutItems />} />
        {/*History pages */}
        <Route path='/about/history-items' element={<HistoryItems />} />
        <Route
          path='/about/history-items/:historyItemId'
          element={<EditHistoryItems />}
        />
        <Route
          path='/about/history-items/create'
          element={<EditHistoryItems />}
        />
        {/*Staff pages */}
        <Route path='/about/staff-items' element={<StaffItems />} />
        <Route
          path='/about/staff-items/:staffItemId'
          element={<EditStaffItems />}
        />
        <Route path='/about/staff-items/create' element={<EditStaffItems />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/terms/:termsId' element={<EditTerms />} />
        <Route element={<Result status='404' title='404' />} />;
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
