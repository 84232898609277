import {Menu} from 'antd';
import React, {useContext, useState} from 'react';
import {
  AppstoreOutlined,
  TeamOutlined,
  PicCenterOutlined,
  FilePdfOutlined,
  FileDoneOutlined,
  InfoCircleOutlined,
  FileOutlined,
} from '@ant-design/icons';
import {useTranslation} from 'react-i18next';
import CommonStore from '../../store/CommonStore';
import {Link, useLocation} from 'react-router-dom';

const {Item} = Menu;

const NavigationBar: React.FC<{theme: 'dark' | 'light'}> = ({theme}) => {
  //
  const {t} = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const location = useLocation();

  //
  const menu = [
    {
      key: '/block-info',
      label: t('Blocks information'),
      icon: <PicCenterOutlined />,
    },
    {
      key: '/brand-items',
      label: t('Brands'),
      icon: <FilePdfOutlined />,
    },
    {
      key: '/team-items',
      label: t('Team'),
      icon: <TeamOutlined />,
    },
    {
      key: '/application-items',
      label: t('Аpplications'),
      icon: <AppstoreOutlined />,
    },
    {
      key: '/news',
      label: t('News'),
      icon: <FileDoneOutlined />,
    },
    {
      key: 'our-offer',
      label: t('Our Offers'),
      icon: <InfoCircleOutlined />,
      children: [
        {
          key: 'our-offer/offer-items',
          label: t('Our offers Articles'),
          icon: <AppstoreOutlined />,
        },
        {
          key: 'our-offer/solution-items',
          label: t('Our offer Solution Cards'),
          icon: <AppstoreOutlined />,
        },
      ],
    },

    {
      key: '/offices',
      label: t('Offices'),
      icon: <AppstoreOutlined />,
    },
    {
      key: 'about',
      label: t('About'),
      icon: <InfoCircleOutlined />,
      children: [
        {
          key: '/about/about-items',
          label: t('About cards'),
          icon: <AppstoreOutlined />,
        },
        {
          key: '/about/history-items',
          label: t('History'),
          icon: <AppstoreOutlined />,
        },
        {
          key: '/about/staff-items',
          label: t('Staff'),
          icon: <AppstoreOutlined />,
        },
      ],
    },
    {
      key: '/terms',
      label: t('Terms and conditions'),
      icon: <AppstoreOutlined />,
    },
  ];

  const activeRoute = location.pathname.split('/')[1] || '';

  const [selectedKey, setSelectedKey] = useState<string>('');

  const handleSelect = (key: string) => {
    setSelectedKey(key);
  };

  return (
    <Menu
      id={`menu-${storeCommon.user?.id}`}
      style={{width: 256}}
      mode='inline'
      theme={theme}
      defaultSelectedKeys={[activeRoute]}
      key={`menu-${activeRoute}`}
      onSelect={key => handleSelect(key.key)}
      items={menu.map(item => ({
        key: item.key,
        label:
          item.key.charAt(0) !== '/' ? (
            <div>
              {item.icon} {t(item.label)}
            </div>
          ) : (
            <>
              <Link to={item.key}>
                {item.icon} {t(item.label)}
              </Link>
            </>
          ),
        children: item.children?.map(sub => ({
          ...sub,
          label: (
            <Link
              to={`${sub.key}`}
              className={selectedKey === sub.key ? 'active' : ''}>
              {sub.label}
            </Link>
          ),
        })),
      }))}></Menu>
  );
};

export default NavigationBar;
